/**
 * Обрезает строку до указанного количества символов.
 *
 * @param {string} str - строка для обрезки.
 * @param {number} max - максимальное количество символов, которое должно оставаться в строке.
 * @returns {string}
 */
export default function useTruncateString(str: string, max: number): string {
  if (!str) {
    return "";
  }
  if (typeof str !== "string") {
    throw new Error(
      'В функции "useTruncateString" первый аргумент должен иметь тип "string"',
    );
  }

  if (!max) {
    throw new Error(
      'В функции "useTruncateString" отсутствует второй аргумент "max"',
    );
  }

  if (typeof max !== "number") {
    throw new Error(
      'В функции "useTruncateString" второй аргумент должен иметь тип "number"',
    );
  }

  if (str.length <= max) {
    return str;
  } else {
    return `${str.slice(0, max)}...`;
  }
}
